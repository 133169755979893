// Custom icon buttons
//
// Creates the .btn-icon variant - you can append the
// .btn-icon class to any .btn component and add an icon
// the inner content of the element you are using - works
// with both feather icons and font awesome icons

.btn-icon {
    padding: 0;

    justify-content: center;
    overflow: hidden;

    border-radius: 100%;

    flex-shrink: 0;

    height: calc(
        (#{$btn-font-size} * #{$btn-line-height}) + (#{$btn-padding-y} * 2) +
            (#{$btn-border-width * 2})
    ) !important;
    width: calc(
        (#{$btn-font-size} * #{$btn-line-height}) + (#{$btn-padding-y} * 2) +
            (#{$btn-border-width * 2})
    ) !important;

    &.btn-xl {
        height: calc(
            (#{$btn-font-size-xl} * #{$btn-line-height}) + (#{$btn-padding-y-xl} * 2) +
                (#{$btn-border-width * 2})
        ) !important;
        width: calc(
            (#{$btn-font-size-xl} * #{$btn-line-height}) + (#{$btn-padding-y-xl} * 2) +
                (#{$btn-border-width * 2})
        ) !important;
        border-radius: 100%;
    }

    &.btn-lg {
        height: calc(
            (#{$btn-font-size-lg} * #{$btn-line-height}) + (#{$btn-padding-y-lg} * 2) +
                (#{$btn-border-width * 2})
        ) !important;
        width: calc(
            (#{$btn-font-size-lg} * #{$btn-line-height}) + (#{$btn-padding-y-lg} * 2) +
                (#{$btn-border-width * 2})
        ) !important;
    }

    &.btn-sm {
        height: calc(
            (#{$btn-font-size-sm} * #{$btn-line-height}) + (#{$btn-padding-y-sm} * 2) +
                (#{$btn-border-width * 2})
        ) !important;
        width: calc(
            (#{$btn-font-size-sm} * #{$btn-line-height}) + (#{$btn-padding-y-sm} * 2) +
                (#{$btn-border-width * 2})
        ) !important;
    }

    &.btn-xs {
        height: calc(
            (#{$btn-font-size-xs} * #{$btn-line-height}) + (#{$btn-padding-y-xs} * 2) +
                (#{$btn-border-width * 2})
        ) !important;
        width: calc(
            (#{$btn-font-size-xs} * #{$btn-line-height}) + (#{$btn-padding-y-xs} * 2) +
                (#{$btn-border-width * 2})
        ) !important;
        border-radius: 100%;
    }

    &.btn-link {
        text-decoration: none;
    }

    // Centers Font Awesome Icons
    .ng-fa-icon {
        display: inline-flex;
    }
}

// Adjust feather icons for usage within .btn component
.btn {
    i-feather,
    .feather {
        height: $btn-font-size;
        width: $btn-font-size;
    }
}
.btn-lg {
    i-feather,
    .feather {
        height: $btn-font-size-lg;
        width: $btn-font-size-lg;
    }
}
.btn-sm {
    i-feather,
    .feather {
        height: $btn-font-size-sm;
        width: $btn-font-size-sm;
    }
}
.btn-xs {
    i-feather,
    .feather {
        height: $btn-font-size-xs;
        width: $btn-font-size-xs;
    }
}
.btn-xl {
    i-feather,
    .feather {
        height: $btn-font-size-xl;
        width: $btn-font-size-xl;
    }
}
